import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35947e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "board-column" }
const _hoisted_2 = { class: "board-column-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerText), 1),
    _createVNode(_component_draggable, _mergeProps({ list: _ctx.list }, _ctx.$attrs, { class: "board-column-content" }), {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (element) => {
          return (_openBlock(), _createElementBlock("div", {
            key: element.id,
            class: "board-item"
          }, _toDisplayString(element.name) + " " + _toDisplayString(element.id), 1))
        }), 128))
      ]),
      _: 1
    }, 16, ["list"])
  ]))
}