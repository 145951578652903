import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50a8380e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "components-container board" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DraggableKanban = _resolveComponent("DraggableKanban")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_DraggableKanban, {
      key: 1,
      list: _ctx.list1,
      group: _ctx.group,
      class: "kanban todo",
      "header-text": "Todo"
    }, null, 8, ["list", "group"])),
    (_openBlock(), _createBlock(_component_DraggableKanban, {
      key: 2,
      list: _ctx.list2,
      group: _ctx.group,
      class: "kanban working",
      "header-text": "Working"
    }, null, 8, ["list", "group"])),
    (_openBlock(), _createBlock(_component_DraggableKanban, {
      key: 3,
      list: _ctx.list3,
      group: _ctx.group,
      class: "kanban done",
      "header-text": "Done"
    }, null, 8, ["list", "group"]))
  ]))
}